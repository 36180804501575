<template>
	<div>
		<div :class="['my-flex-spa-ar home-nav-wrap', {'mobile-nav-height':mobileNavHeight}]">
			<div class="logo-wrap">
				<img class="logo-img" :src="logo" alt="">
			</div>
			<!--			产品特点介绍  产品展示 定制流程 未来火星计划 登记册-->
			<div class="container my-flex">
				<div>
				
				</div>
				<Menu @on-select="onRouterChange" v-if=" menusVisible " mode="horizontal" :theme="theme1"
				      active-name="3">
					<MenuItem name="1">
						<Icon type="ios-paper"/>
						首页
					</MenuItem>
					<MenuItem name="2">
						<Icon type="ios-people"/>
						未来火星计划
					</MenuItem>
					<MenuItem name="3">
						<Icon type="ios-construct"/>
						F&Q
					</MenuItem>
				</Menu>
				<div v-else  class="my-flex" style="justify-content: flex-end">
					<Dropdown @on-click="onSelectMobileMenus">
						<Icon size="40" color="#fff" type="md-menu"></Icon>
						<DropdownMenu slot="list">
							<DropdownItem name="1">
								<Icon type="md-filing" />
								首页</DropdownItem>
							<DropdownItem name="2">
								<Icon custom="iconfont icon-mars_"/>未来火星计划</DropdownItem>
							<DropdownItem name="3">
								<Icon custom="iconfont icon-question"/>F&Q</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		</div>
		<div  class="section-1">
			<el-collapse accordion>
			<el-collapse-item v-for=" (item, index) in text_list " :key="index">
				<template slot="title">
<!--					<div v-if="menusVisible" class="my-flex" style="padding-left: 130px;">-->
					<div class="my-flex" v-if="!menusVisible" style="">
						<i class="el-icon-chat-round" style="font-size: 30px"></i>
						<span style="margin-left: 10px">{{item.title}}</span>
					</div>
					<div class="my-flex" v-else style="padding-left: 130px;">
						<i class="el-icon-chat-round" style="font-size: 30px"></i>
						<span style="margin-left: 10px">{{item.title}}</span>
					</div>
				</template>
				<p v-html="item.content"></p>
<!--				<p>fasdfasdfsd</p>-->
			</el-collapse-item>
			</el-collapse>
		</div>
		<!--		底部大图片-->
		<div style="max-width: 100%;height: auto">
			<img style="width: 100%;height: 100%" :src="thumb" alt="">
		</div>
	</div>
</template>

<script>
export default {
name: "Question",
	data(){
		return {
			mobileNavHeight: !1,
			logo: localStorage.getItem('logo'),
			menusVisible: !0,
			theme1: 'dark',
			text_list: [],
			thumb: localStorage.getItem('thumb')
		}
	},
	mounted() {
		this.getScreen()
		this._loadTextList()
	},
	beforeCreate () {
		document.querySelector('body').setAttribute('style', 'background-color:#fff!important;');
	},
	beforeDestroy() {
		document.querySelector('body').setAttribute('style', 'background-color:#000000!important;');
	},
	methods: {
		/**
		 * 路由切换
		 */
		onRouterChange(e){
			console.log(e);
			if (2==e){
				this.$router.push({
					path: '/about'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}else if (1==e){
				this.$router.push({
					path: '/'
				})
			}
		},
		onSelectMobileMenus(e){
			console.log(e,'====我是触发===');
			if (2==e){
				this.$router.push({
					path: '/about'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}else if (1==e){
				this.$router.push({
					path: '/'
				})
			}
		},
		/**
		 * 获取文章列表数据
		 * @private
		 */
		_loadTextList() {
			this.request({
				url: '/api/getArticles'
			}).then(res => {
				console.log(res, '=====getArticles====');
				if (0 == res.code) {
					if (res.data.length > 0) {
						this.text_list = res.data
					}
				}
			})
		},
		getScreen() {
			let w = document.documentElement.offsetWidth || document.body.offsetWidth;
			if (w < 1000) { // 手机
				this.menusVisible = !1
				this.mobileNavHeight = !0
			}
			console.log(w, '====屏幕宽度====')
		},
	}
}
</script>

<style lang="scss">
.home-nav-wrap {
	background-color: #000000;
	.ivu-menu-dark {
		background: none !important;
	}
}
</style>

<style scoped>

@font-face {font-family: "iconfont";
	src: url('//at.alicdn.com/t/font_2443907_jidau6zvfoa.eot?t=1616641873670'); /* IE9 */
	src: url('//at.alicdn.com/t/font_2443907_jidau6zvfoa.eot?t=1616641873670#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAARIAAsAAAAACHQAAAP7AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDBgqEWIQCATYCJAMMCwgABCAFhG0HOhtMB1GULUqL7EvIyYYXgdAd5kACCPASADCgL7tiYAJCKX4LFsnhzimAeHhao70/O+t3h5hG1yiaIEHCk3rCEyF6KBo6IeMh+fb7aQooTF0vru2gRGpECuFD3gfOy+j8nJ5SE3ITahsYp5NvIZFxRr3hRqbnxFBcAjL/m+1kN5lMKEkksDjtIgo8slHC7QOp6gqLJEz/c8x0UVrP89tsLtlBewMcJdCA4qIT8PhOGG8Yu/IizifQmWpNbsveyTlUZcZmgTjmOQZUU3q5hhgaVVWybxYPlDTpXgq47/99/DAMBElZMLbdeXTXho3P4c8K/7l3EQTjWcEOosBiIBOHSu0HFcL4YgWdIi/W51aykMYK/R+fr0or8x8eSRAVQz6AsPNl6aQENovETxOeteDm6iv0eHvZXSMspJaPwWCQKGUFee/ZDCCkT+f89F2e/auTe1597L2KZt2Z34rb5dPCWcacaCaxelu4m7/9nmgnVVh8HH2MldZO2WL5kgmdiRM7kd7ZWaNMjlGa3lx8rkYpSvgEcJWSEZsVtGj72m5VJXVYMV3VnXEYOUGLljdGAAmkY8uJX6Q8V6e4t6SXATf5bcfrsVO3dVm7ubtaHs14LlcmU9ZSUgG4urR+cXs5ABrIeCZnVNZXx6iNRwM696CXF2rqFxDTqq4O8eTqcaWaOuEmxn91+E7bgMH62eNM1E8/y+CPvSgmPZe4R+C9xDmHz6KenRrVhOSs8QezW9bv+y6691eTmpf32FeTpjFXcMipY61g0ujgqrghT5WMrJBTLabEkF5VkKn2cZ74XYbHR+aesQlvw0v20GZ+pzRlx5FBV03L+Ja00ucpBjllKtj9Dfj5udBvclyu0xFn/A/wykoBSkowF4rx8w5lAWXWdxJ+/piHKZyw6ovAW0koidua7O7AWzUKmY57qs7dSafxvNY9yKBAQBBML9z//HqUNvEph4c5/zVy8SPXXT3Ct4r8L7dsABqJ1oL/LEB9WRUZhiLLfJuvpwpnVd4iF3K5wt8th5F++zvHgNgaGi6AkNSmQKExG5mxi6HUWwKVxnroLLJ/cG8UVhC5xUKfAMJwX5AMeQ+F4b6RGfsDpbH+oTI8wNDZH47ZmxvZ9ZgMIRvND/SsJFaWSepB6Sk5L6HBSZFPvSTWXggH3X42tU8x8Rg76FdnKKJQcRLhHrgYhWGCKSc+WdJ1RdJlr6fK3qhrJRHUbcaIQRAbMn1AHksiptzhop77/VPE8SJk4JqeCvslwjRvfjTQ1W+B3JfHrXqu5SHaK8eQEApSWCKC9oCVhGY8gdLytXzEIrrcHtHUUg8NUm3l3fmV0fOdgY5xoiJSFJGVxw3WT9VfSMs96AAAAA==') format('woff2'),
	url('//at.alicdn.com/t/font_2443907_jidau6zvfoa.woff?t=1616641873670') format('woff'),
	url('//at.alicdn.com/t/font_2443907_jidau6zvfoa.ttf?t=1616641873670') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
	url('//at.alicdn.com/t/font_2443907_jidau6zvfoa.svg?t=1616641873670#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
	font-family: "iconfont" !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mars_:before {
	content: "\ecb4";
}

.icon-question:before {
	content: "\e626";
}

.mobile-nav-height {
	height: 50px;
}
.logo-img {
	width: 50px;
	height: 50px;
}
.section-1 {
	align-items: flex-start;
	min-height: 400px;
	max-height: 600px;
	overflow: hidden;
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
	width: 98%;
	margin: 25px auto;
}
</style>
